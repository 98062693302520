import React, { Component } from 'react';
import { List } from 'semantic-ui-react'

export default class Videos extends Component {
    render() {
        return (
            <div>
                <List>

                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=v9VJql4RebA">
                            Human Garden - Demo
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=Ns-oIcAbu-M">
                            Node-for-Max Supplemental - xeno-canto
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=BLFCsmWai-E">
                            Node-for-Max Supplemental - Internet Samples
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=ci_skDBduc0">
                            Node-for-Max Supplemental - Cat-Wall
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=RUweF_T66Sc">
                            Node-for-Max Supplemental - Sequencing LIFX bulb to the Halloween theme
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=1R5MIllj56g">
                            GBE - Demo
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=F47bpkjXGJw">
                            arduivis - code generation
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=KFZJt0G3Mag">
                            twilioElektron
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=4g4WIx-XFes">
                            Stenos - Demo1
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=LQiBSZEN7Rw">
                            Stenos - Wordlist Demo
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=1o9s9Iw7HtQ">
                            Stenos - Word Highlighting Demo
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=EQibR7PeXIc">
                            Stenos - Quote Demo
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=fwKRmUBYugA">
                            LV426
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=KdeD3HnwbQY">
                            arduivis - pure data - potentiometer
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=br65o5BVZ4s">
                            arduivis-max-adafruit matrix
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=9kNjFVag-MA">
                            arduivis-max-servo
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=bfLyg0-XL6I">
                            arduivis-max-potentiometer
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=fYWAbdd2wJA">
                            arduivis-max-model6
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=vVnFajTOlv4">
                            arduivis-max-model5
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=oUR2ZKST2LU">
                            arduivis-max-model4
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=8jClUFRXWCM">
                            arduivis-max-model3
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=zv2oBC1Jrf0">
                            arduivis-max-model2
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=0152sTZPk4g">
                            arduivis-max-model1
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=BbssgKRB7oI">
                            Multiple Data Streams from Max to NodeJS
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=thfk3WzirjQ">
                            Single Data Stream from Max to NodeJS
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=E3DSPMN0R1g">
                            Connecting Max with an Arduino using Bluetooth (HC-05/HC-06)
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=AyAGFtYR8Pc">
                            Sending strings from Python to Max using an Arduino
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=wJP-JNxSfyU">
                            Sampling with a Butt Set in Max
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=iWWJ1rU2U6Q">
                            Serial buffer limitations in Max using an Arduino
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=LrZKLl2PnqM">
                            Controlling a LED in Max with an Arduino in under 40 seconds
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=yc6p46G6d9M">
                            Controlling audio in Max with an Arduino in under 1 minute
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=xzbmW9_wAkA">
                                generativeBreakpointEnvelope (ex2)
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=7SjVqSB3FCI">
                                generativeBreakpointEnvelope (ex1)
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=BmCjPFPqkhs">
                                Qu-Bit Electronix - Nebulae - TRcross
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=ZnE7loVhHG0">
                                Qu-Bit Electronix - Nebulae - GENDYN
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?  v=n-v9e-HH9lE">
                                Qu-Bit Electronix - Nebulae - Grain3
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?  v=n-v9e-HH9lE">
                                Qu-Bit Electronix - Nebulae - Grain3
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=Ss40BPOTNAU">
                                Nona Hendryx - Re-Wired
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=3fNFcyjXqM4">
                                wiiAirDrum
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=A9k4SP2e7rU">
                                Optical Sonance v2
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='video' />
                        <List.Content>
                            <List.Header as='a' href="https://www.youtube.com/watch?v=AuArRzAGHow">
                                Optical Sonance v1
                            </List.Header>
                            <List.Description></List.Description>
                        </List.Content>
                    </List.Item>
                </List>
            </div>
        );
    }
}